<!-- FoldingProductionComponent -->
<template>
  <div
    class="flex gap-8 py-16 items-center border-t-1 border-solid border-border first:border-none"
  >
    <button type="button" class="shrink-0 ml-20 h-20" @click="toggleDetail">
      <s-icon
        size="3xl"
        icon="ic-v2-control-arrow-right-line"
        class="text-on-surface-elevation-1 flex"
        :class="{ 'rotate-90': isOpenDetail }"
      />
    </button>
    <div class="flex items-center flex-1">
      <checkbox
        v-if="state === CheckboxStateEnum.INDETERMINATE"
        :id="`projects[${index}].isSelected`"
        :options="{
          size: 'sm',
          align: 'middle'
        }"
        :modelValue="true"
        :state="state"
        @update:modelValue="selectProject(index, isOpenDetail)"
      >
        <st-tooltip-text-over position="bottom-start" hasArrow :content="item.name">
          <span class="line-clamp-1 break-all text-md font-bold">{{ item.name }}</span>
        </st-tooltip-text-over>
      </checkbox>
      <checkbox
        v-else
        :id="`projects[${index}].isSelected`"
        :options="{
          size: 'sm',
          align: 'middle'
        }"
        :name="`projects[${index}].isSelected`"
        :state="state"
        @update:modelValue="selectProject(index, isOpenDetail)"
      >
        <st-tooltip-text-over position="bottom-start" hasArrow :content="item.name">
          <span class="line-clamp-1 break-all text-md font-bold">{{ item.name }}</span>
        </st-tooltip-text-over>
      </checkbox>
    </div>
    <div class="min-w-[11.6rem] shrink-0 ml-8">
      <dropdown
        :disabled="isProjectDisabled"
        :dropdownProps="{
          distance: -4,
          placement: 'bottom-end',
          size: 'sm',
          offset: [0, 0],
          variant: 'line',
          class: 'w-[11.6rem]',
          itemDropdownClass: 'w-200'
        }"
        :name="`projects[${index}].role`"
        :placeholder="$t('studio.group.collection.storage_select_prj_cf_btn')"
        :options="roleList ?? []"
        :useTooltip="true"
        @update:modelValue="$emit('updateProjectRole', index, $event)"
      />
    </div>
  </div>
  <div v-if="isOpenDetail" class="pl-[7.2rem] flex gap-4 flex-col -mt-8 pb-16">
    <template v-if="item.products && item.products.length > 0">
      <div
        v-for="(product, productIndex) in item.products"
        :key="productIndex"
        class="flex items-center gap-16"
      >
        <div class="flex items-center flex-1">
          <checkbox
            :id="`projects[${index}].products[${productIndex}].isSelected`"
            :options="{
              size: 'sm',
              align: 'middle'
            }"
            :name="`projects[${index}].products[${productIndex}].isSelected`"
            @update:modelValue="$emit('selectProduct', index, productIndex, $event)"
          >
            <st-tooltip-text-over position="bottom-start" hasArrow :content="product.productName">
              <span class="line-clamp-1 break-all text-md font-bold">{{
                product.productName
              }}</span>
            </st-tooltip-text-over>
          </checkbox>
        </div>
        <div class="min-w-[11.6rem] shrink-0 ml-8">
          <dropdown
            :dropdownProps="{
              distance: -4,
              placement: 'bottom-end',
              size: 'sm',
              offset: [0, 0],
              variant: 'line',
              class: 'w-[11.6rem] truncate',
              itemDropdownClass: 'w-200'
            }"
            :disabled="!product.isSelected"
            :options="roleList ?? []"
            :placeholder="$t('studio.group.collection.storage_select_prj_cf_btn')"
            :name="`projects[${index}].products[${productIndex}].role`"
            :useTooltip="true"
            @update:modelValue="$emit('updateProductRole', index)"
          />
        </div>
      </div>
    </template>
    <template v-else> - </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { CheckboxStateEnum } from '@/enums/common.enum';
import type { FormOption } from '@/types/common/form.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';

defineProps<{
  roleList: FormOption<number>[];
  item: ProjectProductModel;
  index: number;
  isProjectDisabled?: boolean;
  state?: string;
}>();

const emits = defineEmits<{
  selectProject: [{ index: number; isOpenDetail?: boolean }];
  selectProduct: [v: number, productIndex: number, value: boolean];
  updateProjectRole: [index: number, v: number | string];
  updateProductRole: [index: number];
  onFold: [v: boolean];
}>();

const isOpenDetail = ref<boolean>();

const toggleDetail = () => {
  isOpenDetail.value = !isOpenDetail.value;
  emits('onFold', isOpenDetail.value);
};

const selectProject = (index: number, isOpenDetail?: boolean) => {
  emits('selectProject', { index, isOpenDetail });
};
</script>
