<template>
  <div v-if="!isStepDisabled" class="mt-16 mb-32 gap-8 flex">
    <span class="h-4 rounded-full flex-1 bg-on-surface-elevation-2"></span>
    <span class="h-4 rounded-full flex-1 bg-[var(--stds-glob-color-gray60)]"></span>
  </div>
  <div class="flex gap-4 items-start">
    <h2 class="text-xl leading-lg font-bold text-on-surface-elevation-1 flex-1">
      {{
        ownerYn
          ? $t('studio.member_manage.member_details_group_owner_menu_permission_title')
          : $t('studio.member_manage.member_details_group_menu_permission_title')
      }}
    </h2>
  </div>
  <p class="mt-4 text-md leading-lg font-regular text-on-surface-elevation-3">
    {{ $t('studio.member_manage.member_details_group_menu_permission_msg1') }}
  </p>

  <!-- Permission and role for product/project -->
  <div class="mt-16 pb-8 border-b-1 border-solid border-border">
    <m-table>
      <colgroup>
        <col style="width: auto" />
        <col style="width: 9.6rem" />
        <col style="width: 9.6rem" />
      </colgroup>
      <thead>
        <tr>
          <st-th
            v-model:checkBoxValue="checkAll"
            :checkboxUse="!ownerYn"
            :thTitle="$t('studio.gnb.group_myinfo.my_group.detailed_group_edit_per_menu_name')"
            @onCheckboxUpdate="selectAll"
          />
          <st-th aCenter :thTitle="$t('studio.member_manage.role_manage_list_view_per')" />
          <st-th
            aCenter
            :thTitle="$t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')"
            class="flex gap-4 w-full justify-center items-center"
          >
            <button type="button" class="h-16" @click="openEditingPermissionDialog">
              <s-icon
                size="xl"
                icon="ic-v2-state-help-circle-line"
                class="text-on-surface-elevation-4 flex"
              />
            </button>
          </st-th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in menus" :key="`${item.viewable}-${item.editable}-${index}`">
          <st-td aLeft class="!py-[.7rem]">
            <span class="text-md text-on-surface-elevation-3">{{ getMenuName(item.id) }}</span>
          </st-td>
          <st-td onlyButton>
            <div class="h-16">
              <checkbox
                v-if="!ownerYn"
                :id="`${menus[index]}-viewable`"
                :options="{
                  size: 'sm',
                  align: 'middle'
                }"
                :name="`menus[${index}].viewable`"
                :disabled="item.id === MenuAuthorityIds.GROUP_HOME"
                @update:modelValue="updateViewable($event, index)"
              />
              <span v-else class="w-[9.6rem] flex shrink-0 items-center justify-center">
                <s-icon
                  icon="ic-v2-control-check-bold-line"
                  size="3xl"
                  :class="{
                    'text-on-surface-elevation-1': item.viewable,
                    'text-disabled-variant-2': !item.viewable
                  }"
                  class="flex"
                />
              </span>
            </div>
          </st-td>
          <st-td onlyButton>
            <div class="h-16">
              <template v-if="!ownerYn">
                <checkbox
                  v-if="
                    menus[index].id !== MenuAuthorityIds.STATISTICS &&
                      menus[index].id !== MenuAuthorityIds.BILL_SETTLEMENT
                  "
                  :id="`${menus[index]}-editable`"
                  :options="{
                    size: 'sm',
                    align: 'middle'
                  }"
                  :name="`menus[${index}].editable`"
                  :disabled="!menus[index].viewable"
                />
                <checkbox
                  v-else
                  :id="`${menus[index]}-editable`"
                  :options="{
                    size: 'sm',
                    align: 'middle'
                  }"
                  :modelValue="false"
                  disabled
                />
              </template>

              <span v-else class="w-[9.6rem] flex shrink-0 items-center justify-center pr-8">
                <s-icon
                  icon="ic-v2-control-check-bold-line"
                  size="3xl"
                  :class="{
                    'text-on-surface-elevation-1': item.editable,
                    'text-disabled-variant-2': !item.editable
                  }"
                  class="flex"
                />
              </span>
            </div>
          </st-td>
        </tr>
      </tbody>
    </m-table>
  </div>

  <!-- Set auto role -->
  <div>
    <p class="mt-8 text-xs leading-xs font-regular text-placeholder">
      {{ $t('studio.member_manage.member_details_group_menu_permission_msg2') }}
    </p>
    <template v-if="!ownerYn">
      <h3 class="mt-24 text-md leading-lg font-bold text-on-surface-elevation-2">
        {{ $t('studio.member_manage.member_details_group_menu_permission_mail_or_notice_title') }}
      </h3>
      <p class="mt-4 text-xs leading-xs font-regular text-placeholder">
        {{ $t('studio.member_manage.member_details_group_menu_permission_mail_or_notice_msg') }}
      </p>
      <div class="mt-12 flex flex-wrap gap-x-24 gap-y-12">
        <checkbox
          id="notification-chk1-01"
          name="isSettlement"
          :options="{
            size: 'sm',
            align: 'middle'
          }"
        >
          {{ $t('studio.lnb.common_2depth_studio_settlement') }}
        </checkbox>
        <checkbox
          id="notification-chk1-02"
          :options="{
            size: 'sm',
            align: 'middle'
          }"
          name="isVerification"
        >
          {{
            $t(
              'studio.member_manage.member_details_group_menu_permission_mail_or_notice_review_chkbox'
            )
          }}
        </checkbox>
        <checkbox
          id="notification-chk1-03"
          :options="{
            size: 'sm',
            align: 'middle'
          }"
          name="isContract"
        >
          {{
            $t(
              'studio.member_manage.member_details_group_menu_permission_mail_or_notice_contract_chkbox'
            )
          }}
        </checkbox>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, shallowRef, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import EditingPermissionDialog from '@/components/my-info/editing-permission-dialog.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { showDialog } from '@/composables/useDialog';
import { MenuAuthorityIds } from '@/enums/common.enum';
import type { MenuRole } from '@/types/member/member-request.type';

const props = defineProps<{
  isStepDisabled?: boolean;
  ownerYn?: boolean;
}>();

const { ownerYn } = toRefs(props);

const { t } = useI18n();

const menus = useFieldValue<MenuRole[]>('menus');
const setMenusValue = useSetFieldValue<MenuRole[]>('menus');

const checkAll = computed(() => {
  if (!menus.value || menus.value.length === 0 || ownerYn.value) {
    return false;
  }

  return menus.value.every((role: MenuRole) => role.editable && role.viewable);
});

const selectAll = (e: boolean) => {
  const allChecked = menus.value.map((role: MenuRole) => {
    role.editable = e;

    if (role.id === MenuAuthorityIds.GROUP_HOME) {
      role.viewable = true;
    } else {
      role.viewable = e;
    }

    return role;
  });

  setMenusValue(allChecked);
};

const updateViewable = (e: boolean, index: number) => {
  if (!e) {
    const updatedRoles = menus.value.map((role: MenuRole, i: number) => {
      if (i === index) {
        role.editable = false;
      }

      return role;
    });
    setMenusValue(updatedRoles);
  }
};

const openEditingPermissionDialog = async () => {
  await showDialog({
    component: shallowRef(EditingPermissionDialog),
    severity: 'info'
  });
};

const getMenuName = (menuId?: MenuAuthorityIds) => {
  if (!menuId) {
    return '';
  }

  switch (menuId) {
    case MenuAuthorityIds.GROUP_HOME:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_home');
    case MenuAuthorityIds.PROJECT_PRODUCT:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_prj_prod');
    case MenuAuthorityIds.COLLECTION:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_collection');
    case MenuAuthorityIds.STORE_COMMUNITY:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_store_comm');
    case MenuAuthorityIds.MEMBER_MANAGEMENT:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_mem_mngmt');
    case MenuAuthorityIds.BILL_SETTLEMENT:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_bill_settlement');
    case MenuAuthorityIds.STATISTICS:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_statistics');
    case MenuAuthorityIds.BIMS_BANK_SETTING:
      return t('studio.gnb.group_myinfo.my_group.detailed_group_per_biz_bank_info');
    default:
      return menuId;
  }
};
</script>
