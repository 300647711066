<template>
  <div
    :class="
      isFullPage
        ? 'absolute z-[9999] !w-full top-0 left-0 bg-white p-32 !h-[100dvh] overflow-auto'
        : ''
    "
  >
    <div v-if="!isStepDisabled" class="mt-16 mb-32 gap-8 flex shrink-0">
      <span class="h-4 rounded-full flex-1 bg-on-surface-elevation-2"></span>
      <span class="h-4 rounded-full flex-1 bg-on-surface-elevation-2"></span>
    </div>

    <div class="shrink-0">
      <div class="flex gap-4 items-start">
        <h2 class="text-xl leading-lg font-bold text-on-surface-elevation-1 flex-1">
          {{
            ownerYn
              ? $t('studio.member_manage.member_details_owner_product_permission_title')
              : $t('studio.member_manage.member_details_product_permission_title')
          }}
        </h2>
        <s-button
          variant="outline"
          size="xs"
          :icon="
            isFullPage ? 'ic-v2-media-av-fullscreen-exit-fill' : 'ic-v2-media-av-fullscreen-fill'
          "
          @click="isFullPage = !isFullPage"
        >
          {{
            isFullPage
              ? $t('studio.prj_prod_mngmt.prod_home.create_term.btn_exit_fullscr')
              : $t('studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr')
          }}
        </s-button>
      </div>
      <p v-if="!ownerYn" class="mt-4 text-md leading-lg font-regular text-on-surface-elevation-3">
        {{ $t('studio.member_manage.member_details_product_permission_msg1') }}.
      </p>
    </div>

    <!-- Permission and role for product/project -->
    <div class="mt-16 border-b-1 border-solid border-border">
      <m-table>
        <colgroup>
          <col style="width: auto" />
          <col style="width: 40%" />
        </colgroup>
        <thead>
          <tr>
            <st-th
              v-model:checkBoxValue="selectedAllProject"
              :checkboxUse="!ownerYn"
              class="bg-[#F7FAFD]"
              :thTitle="
                $t('studio.member_manage.member_details_product_permission_product_name_header1')
              "
              :disabled="isItemDisabled"
              @onCheckboxUpdate="selectAll"
            />
            <st-th class="!px-12 !py-0 bg-[#F7FAFD]">
              <div v-if="!ownerYn" class="flex items-center gap-8 justify-end">
                <div class="header-role-custom">
                  <dropdown
                    v-model="selectedAllRole"
                    :options="options"
                    :disabled="!selectedAllProject || isItemDisabled"
                    :dropdownProps="{
                      distance: 0,
                      placement: 'bottom-end',
                      variant: 'text',
                      itemDropdownClass: 'w-200',
                      class: 'w-full'
                    }"
                    :placeholder="
                      $t(
                        'studio.member_manage.member_details_product_permission_role_selection_dropbox_header2'
                      )
                    "
                    :useTooltip="true"
                    containerClass="max-w-[250px]"
                  />
                </div>
                <s-button
                  variant="outline"
                  size="xs"
                  :disabled="!selectedAllProject || isItemDisabled"
                  class="truncate min-w-[6.2rem]"
                  @click="updateAllRoles"
                >
                  {{ $t('studio.member_manage.member_details_product_permission_apply_all_btn') }}
                </s-button>
              </div>
            </st-th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="projectList.length > 0" colspan="2">
              <template v-if="!ownerYn">
                <folding-production
                  v-for="(project, projectIndex) in projectList"
                  :key="projectIndex"
                  :item="project"
                  :index="projectIndex"
                  :roleList="options"
                  :noCheckMark="!ownerYn"
                  :noBackground="!ownerYn"
                  :isProjectDisabled="!project.isSelected"
                  :state="getProjectState(project)"
                  @selectProject="selectProject($event)"
                  @selectProduct="selectProduct"
                  @updateProjectRole="updateProjectRole"
                  @updateProductRole="updateProductRole"
                />
              </template>

              <template v-else>
                <div
                  v-for="project in projectList"
                  :key="project.id"
                  class="border-b-1 border-solid border-border py-16"
                >
                  <st-folding roleType noCheckMark noBackground :name="project.name">
                    <div class="flex flex-col gap-4 pt-8 ml-36">
                      <template v-if="project.products && project.products?.length > 0">
                        <s-text
                          v-for="product in project.products"
                          :key="product.productNo"
                          as="p"
                          role="text3"
                          class="py-4"
                        >
                          {{ product.productName }}
                        </s-text>
                      </template>
                      <template v-else> - </template>
                    </div>
                  </st-folding>
                </div>
              </template>
            </td>
            <st-td v-else-if="projectList.length === 0 && !isLoading" colspan="2" aCenter>
              <safe-html
                :html="
                  $t('studio.member_manage.member_details_product_permission_product_place_holder')
                "
              />
            </st-td>
          </tr>
        </tbody>
      </m-table>
    </div>

    <!-- Set auto role -->
    <div v-if="!ownerYn" class="mt-16 flex gap-8 items-center">
      <div class="flex-1">
        <checkbox
          id="autoRoleYn"
          :options="{ size: 'sm', align: 'middle' }"
          name="autoRoleYn"
          @update:modelValue="setAutoRole"
        >
          {{ $t('studio.member_manage.member_details_product_permission_msg2') }}
        </checkbox>
      </div>
      <div class="w-[11.6rem]">
        <dropdown
          :disabled="!autoRoleYn"
          :dropdownProps="{
            distance: -4,
            placement: 'bottom-end',
            size: 'sm',
            offset: [0, 0],
            variant: 'line',
            class: 'w-[11.6rem]',
            itemDropdownClass: 'w-200'
          }"
          :options="options"
          :placeholder="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_rating_place_holder'
            )
          "
          name="autoRoleNo"
          :useTooltip="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, watch } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StFolding from '@/components/common/st-folding.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import FoldingProduction from '@/components/member-mng/invite/folding-production.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { ROLE_OWNER } from '@/constants/permission.const';
import { CheckboxStateEnum } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useRoleStore } from '@/stores/role.store';
import type { FormOption } from '@/types/common/form.type';
import type { MemberInfoModel } from '@/types/member/member-model.type';
import type { ProductRole } from '@/types/member/member-request.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';
import type { RoleType } from '@/types/role/role.request.type';
import { getRoleName } from '@/utils/permission.util';

defineProps<{
  isStepDisabled?: boolean;
  isSubmitting?: boolean;
  ownerYn?: boolean;
  memberData?: MemberInfoModel;
}>();

const roleStore = useRoleStore();
const appStore = useAppStore();

const { roleList } = storeToRefs(roleStore);
const { isLoading } = storeToRefs(appStore);

const autoRoleYn = useFieldValue<boolean>('autoRoleYn');
const projectList = useFieldValue<ProjectProductModel[]>('projects');
const setProjectsValue = useSetFieldValue<ProjectProductModel[]>('projects');
const setAutoRoleNo = useSetFieldValue('autoRoleNo');

const selectedAllRole = ref<string>('');
const isFullPage = ref<boolean>(false);

const isItemDisabled = computed(() => projectList.value.length === 0);

const selectedAllProject = computed(() => {
  if (projectList.value.length === 0) {
    return false;
  }

  return projectList.value.every(
    (project: ProjectProductModel) =>
      project.isSelected && project.products?.every((product: ProductRole) => product.isSelected)
  );
});

const options = computed<FormOption<number>[]>(() => {
  return (roleList.value?.contents ?? [])
    .filter((r: RoleType) => r.roleName !== ROLE_OWNER)
    .map((role: RoleType) => ({
      label: getRoleName(role.roleName),
      value: role.roleNo
    }));
});

const selectProject = async (e: { index: number; isOpenDetail?: boolean }) => {
  const { index } = e;

  const updatedProjects = projectList.value.map((project: ProjectProductModel, i: number) => {
    if (i === index) {
      project.expand = !project.expand;
      if (!project.products) {
        return project;
      }

      project.products.forEach((product: ProductRole) => (product.isSelected = project.isSelected));

      // set product's role to undefined if it is not selected
      if (!project.isSelected) {
        project.role = undefined;
        project.products.forEach((product: ProductRole) => (product.role = undefined));
      }
    }

    return project;
  });
  setProjectsValue(updatedProjects);
};

const selectProduct = (index: number, productIndex: number, value: boolean) => {
  const updatedProjects = projectList.value.map((project: ProjectProductModel, i: number) => {
    if (i === index) {
      project.isSelected = project.products?.every((product: ProductRole) => product.isSelected);

      if (!project.products) {
        return project;
      }

      project.products[productIndex].isSelected = value;

      // set product's role to undefined if it is not selected
      if (!value) {
        project.products[productIndex].role = undefined;
      }
    }

    return project;
  });

  setProjectsValue(updatedProjects);
};

const selectAll = (e: boolean) => {
  const updatedProjects = projectList.value.map((project: ProjectProductModel) => {
    project.isSelected = e;

    if (!e) {
      project.role = undefined;
    }

    project.products?.forEach((product: ProductRole) => {
      product.isSelected = e;

      if (!e) {
        product.role = undefined;
      }
    });

    return project;
  });

  setProjectsValue(updatedProjects);
};

const updateAllRoles = () => {
  if (!selectedAllRole.value) {
    return;
  }

  const updatedProjects = projectList.value.map((project: ProjectProductModel) => {
    project.role = selectedAllRole.value;
    project.products?.forEach((product: ProductRole) => (product.role = selectedAllRole.value));

    return project;
  });

  setProjectsValue(updatedProjects);
};

const updateProjectRole = (index: number, e: string | number) => {
  const updatedProjects = projectList.value.map((project: ProjectProductModel, i: number) => {
    if (i === index) {
      project.products?.forEach((product: ProductRole) => {
        if (product.isSelected) {
          product.role = e;

          selectedAllRole.value = '';
        }
      });
    }

    return project;
  });

  setProjectsValue(updatedProjects);
};

const updateProductRole = (index: number) => {
  selectedAllRole.value = '';

  const updatedProjects = projectList.value.map((project: ProjectProductModel, i: number) => {
    if (i === index && project.role) {
      project.role = undefined;
    }

    return project;
  });

  setProjectsValue(updatedProjects);
};

const setAutoRole = (value: boolean) => {
  if (!value) {
    setAutoRoleNo(undefined);
  }
};

const getProjectState = (project: ProjectProductModel) => {
  if (!project.products) {
    return '';
  }

  const selectedProducts = project.products.filter((product: ProductRole) => product.isSelected);

  if (selectedProducts.length === 0) {
    return '';
  }

  if (selectedProducts.length === project.products.length) {
    return CheckboxStateEnum.SELECT_ALL;
  }

  return CheckboxStateEnum.INDETERMINATE;
};

watch(
  () => isFullPage.value,
  (value: boolean) => {
    if (value) {
      document.body.style.overflow = 'hidden';

      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    } else {
      document.body.style.overflow = '';
    }
  }
);
</script>
