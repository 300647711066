<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.lnb.common_2depth_member_management'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.member_details_pg_title')
      }
    ]"
    :title="$t('studio.member_manage.member_details_pg_title')"
  />

  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <div class="flex flex-col gap-16">
          <dl class="flex gap-4 flex-col">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.member_manage.member_details_msg2') }}
            </dt>
            <dd
              class="text-xl font-bold text-on-surface-elevation-2 leading-2xl flex gap-8 items-center"
            >
              {{ memberData?.nickname }}
            </dd>
          </dl>
          <dl class="flex gap-4 flex-col">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.member_manage.member_details_msg3') }}
            </dt>
            <dd
              class="text-xl font-bold text-on-surface-elevation-2 leading-2xl flex gap-8 items-center"
            >
              {{ memberData?.joinedAt ? getDateTimeByLocale(memberData?.joinedAt) : '' }}
            </dd>
          </dl>
        </div>
      </st-box>
      <st-box class="mt-24">
        <menu-role isStepDisabled :ownerYn="ownerYn" />
      </st-box>
      <st-box class="mt-24">
        <project-product-role
          isStepDisabled
          :isSubmitting="isSubmitting"
          :ownerYn="ownerYn"
          :memberData="memberData"
        />
      </st-box>
      <div v-if="!ownerYn" class="mt-40 flex justify-center gap-16">
        <s-button
          v-if="userInfo?.memberNo !== memberData?.memberNo"
          variant="outline"
          size="lg"
          class="!min-w-160"
          @click="onKickOut"
        >
          {{ $t('studio.member_manage.member_details_remove_btn') }}
        </s-button>
        <s-button variant="primary" size="lg" class="!min-w-160" @click="onSave">
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.member_manage.member_details_right_guide1')"
        numberValue="01"
      >
        <safe-html :html="$t('studio.member_manage.member_details_right_guide2')" />
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="$t('studio.member_manage.member_details_right_guide3')"
        numberValue="02"
        class="mt-24"
      >
        <safe-html
          :html="`${$t('studio.member_manage.member_details_right_guide4')}<br /><br />${$t(
            'studio.member_manage.member_details_right_guide5'
          )}`"
        />
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import {
  editMemberDetailApi,
  fetchMemberDetailApi,
  fetchMenuList,
  kickMemberApi
} from '@/apis/member.api';
import { fetchProductListByProjectApi } from '@/apis/products.api';
import { fetchProjectListApi } from '@/apis/project-product.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import MenuRole from '@/components/member-mng/invite/menu-role.vue';
import ProjectProductRole from '@/components/member-mng/invite/project-product-role.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { MEMBER_MNG_PAGE_URL } from '@/constants/url.const';
import { MenuAuthorityIds, Permission } from '@/enums/common.enum';
import { NotificationId } from '@/enums/member.enum';
import { memberInfoMapper } from '@/mappers/member-mng.mapper';
import { projectListMapper } from '@/mappers/project.mapper';
import { useRoleStore } from '@/stores/role.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { MemberInfoModel } from '@/types/member/member-model.type';
import {
  type MemberInvite,
  type MemberNotifications,
  type MemberRole,
  type MenuAuthority,
  type MenuRole as MenuAuthorityRole,
  type ProductRole
} from '@/types/member/member-request.type';
import type {
  ProductModel,
  ProjectProductModel
} from '@/types/project-product/project-product-model.type';
import type { ProductListByProjectResponse } from '@/types/project-product/project-product-response.type';
import { getBoolean, redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

definePageMeta({
  title: '멤버관리',
  middleware: 'check-menu-writable-middleware'
});

const app = useApp();
const route = useRoute();

const roleStore = useRoleStore();
const userStore = useUserStore();

const { selectedGroupId, userInfo } = storeToRefs(userStore);

const { t } = useI18n();

const { handleSubmit, setValues } = useForm<MemberInvite>({
  initialValues: {
    projects: [], // Permission for Project and Product
    menus: [], // Permission for LNB
    autoRoleYn: false,
    autoRoleNo: '',
    isSettlement: false,
    isVerification: false,
    isContract: false,
    email: ''
  },
  keepValuesOnUnmount: true
});

const memberId = (route.params.id as string) ?? '';
const groupId = route.params.groupId as string;

const memberData = ref<MemberInfoModel>();
const allProjects = ref<ProjectProductModel[]>([]);
const allProducts = ref<ProductRole[]>([]);

const isSubmitting = ref<boolean>(false);
const ownerYn = ref<boolean>(false);
const isCheckLeave = ref<boolean>(true);

const getNotification = (notificationId: MemberNotifications['notificationId']) => {
  return memberData.value?.notifications.find(
    (notification: MemberNotifications) => notification.notificationId === notificationId
  );
};

const fetchMemberData = async (memberId: string) => {
  memberData.value = await fetchMemberDetailApi({
    groupId: selectedGroupId.value,
    memberId: Number(memberId)
  });

  memberData.value?.memberRoles.forEach((r: MemberRole) =>
    allProducts.value.push(...r.productRoles)
  );
  ownerYn.value = getBoolean(memberData.value.ownerYn);

  setValues({
    projects: allProjects.value,
    email: memberData.value.email,
    isContract:
      getNotification(NotificationId.CONTRACTION)?.notificationId === NotificationId.CONTRACTION,
    isVerification:
      getNotification(NotificationId.VERIFICATION)?.notificationId === NotificationId.VERIFICATION,
    isSettlement:
      getNotification(NotificationId.BILL_SETTLEMENT)?.notificationId ===
      NotificationId.BILL_SETTLEMENT,
    autoRoleYn: getBoolean(memberData.value.autoRoleYn),
    autoRoleNo: memberData.value.autoRoleNo
  });

  setProductsRole();
  setProjectSelected();
};

const setProjectSelected = () => {
  allProjects.value.forEach((project: ProjectProductModel) => {
    if (!project.products || project.products.length === 0) {
      return;
    }

    const isAllSelected = project.products.every((product: ProductModel) => product.isSelected);
    if (isAllSelected) {
      project.isSelected = true;
    }
  });
};

const setProductsRole = () => {
  allProjects.value.forEach((project: ProjectProductModel) => {
    project.products?.forEach((product: ProductModel) => {
      const res = allProducts.value.find((p: ProductRole) => p.productNo === product.productNo);
      if (res) {
        product.isSelected = true;
        product.role = res.memberRoleNo;
      }
    });
  });
};

const fetchAllMenu = async () => {
  const menus = await fetchMenuList();

  menus.map((menu: MenuAuthorityRole) => {
    const data = memberData.value?.menuAuthorities.find(
      (menuAuthority: MenuAuthority) => menuAuthority.menuAuthorityId === menu.id
    );

    if (ownerYn.value) {
      menu.viewable = true;
      menu.editable = true;

      return menu;
    }

    menu.viewable = data?.permission === Permission.READ || data?.permission === Permission.WRITE;
    menu.editable = data?.permission === Permission.WRITE;

    return menu;
  });

  setValues({
    menus
  });
};

const showError = async (err: any) => {
  const error = err as ErrorResponse;
  const errorCode = error.statusCode ?? 0;
  if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
    return;
  }
  await showAlert({
    severity: 'info',
    content: t(COMMON_ERROR_MESSAGE_KEY),
    confirmClasses: 'max-w-full'
  });
};

const onSave = handleSubmit(async (value: MemberInvite) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  try {
    let isAllSelected: boolean = true;
    value.projects.forEach((project: ProjectProductModel) => {
      if (project.products) {
        project.products.forEach((product: ProductModel) => {
          if (product.isSelected) {
            if (!product.role) {
              isAllSelected = false;
            }
          }
        });
      }
    });

    if (!isAllSelected) {
      showAlert({
        content: t('studio.member_manage.member_details_role_setting_required_popup')
      });

      return;
    }

    const data = memberInfoMapper(value);
    if (!data.autoRoleNo) {
      delete data.autoRoleNo;
    }

    if (getBoolean(data.autoRoleYn)) {
      if (!data.autoRoleNo) {
        showAlert({
          content: t('studio.member_manage.member_details_role_setting_required_popup')
        });

        return;
      }
    }

    isSubmitting.value = true;

    data.menuAuthorities.forEach((menu: MenuAuthority) => {
      if (
        menu.menuAuthorityId === MenuAuthorityIds.STATISTICS ||
        menu.menuAuthorityId === MenuAuthorityIds.BILL_SETTLEMENT
      ) {
        menu.permission = Permission.READ;
      }

      return menu;
    });

    await editMemberDetailApi({
      groupId: selectedGroupId.value,
      memberId: memberId ?? '',
      data
    });

    const reDirect = await showAlert({
      severity: 'info',
      content: t('studio.common.popup_case_d_complete_save')
    });

    if (reDirect) {
      isCheckLeave.value = false;
      redirectTo(MEMBER_MNG_PAGE_URL);
    }
  } catch (err: any) {
    showError(err);
  }

  isSubmitting.value = false;
});

const onKickOut = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  const result = await showConfirm({
    content: t('studio.member_manage.member_details_remove_step1_msg'),
    confirmLabel: t('studio.member_manage.member_details_remove_step1_remove_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline',
    confirmVariant: 'red'
  });

  if (!result) {
    return;
  }

  try {
    await kickMemberApi({ groupId: selectedGroupId.value, memberNo: memberId });

    const direct = await showAlert({
      content: t('studio.member_manage.member_details_remove_step2_msg')
    });

    if (!direct) {
      return;
    }

    isCheckLeave.value = false;
    redirectTo(MEMBER_MNG_PAGE_URL);
  } catch (err: any) {
    showError(err);
  }
};

const getAllProductsByProject = async () => {
  const promises: Promise<ProductListByProjectResponse | undefined>[] = [];

  if (allProjects.value.length > 0) {
    for (let i = 0; i < allProjects.value.length; i++) {
      allProjects.value[i].expand = false;

      promises.push(
        fetchProductListByProjectApi({
          projectId: allProjects.value[i].id,
          size: 9999
        })
      );
    }
  }

  const allSettled = await Promise.allSettled(promises);
  const allProducts = allSettled
    .filter((promise: any) => promise.value && promise.status === 'fulfilled')
    .map((promise: any) => promise.value);
  allProducts.forEach((productList: ProductListByProjectResponse) => {
    const project = allProjects.value.find(
      (p: ProjectProductModel) => p.id === productList.projectId
    );
    if (project) {
      project.products = productList.contents;
    }
  });
};

const init = async (memberId: string) => {
  try {
    const result = await fetchProjectListApi({ groupId, size: 9999 }, false);
    if (result) {
      const data = projectListMapper(result);

      allProjects.value = data.contents;

      await getAllProductsByProject();
    }

    await fetchMemberData(memberId);
    await roleStore.fetchRoleList(selectedGroupId.value, {
      page: 1,
      size: 9999
    });
    await fetchAllMenu();
  } catch (err: any) {
    showError(err);
  }
};

init(memberId);

onBeforeRouteLeave(async () => {
  document.body.style.overflow = '';

  if (ownerYn.value) {
    return;
  }

  if (isCheckLeave.value) {
    return showConfirmLeaveDialog();
  }
});
</script>
